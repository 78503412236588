import Loader from '@/components/common/ui/loader.jsx'
import { cn } from '@/lib/utils.js'
import { CheckCircle, Info, ShieldCheck, TriangleAlert } from 'lucide-react'

const statusSection = ({ text, icon, type }) => {
  return (
    <div
      className={cn(
        'text-xs flex items-center gap-2 rounded-lg w-full px-2 py-2 ',
        {
          'bg-green-50 text-green-600': type === 'success',
          'bg-red-50 text-destructive': type === 'danger',
          'bg-gray-200 text-foreground': type === 'loading'
        }
      )}
    >
      <p>{icon}</p>
      <p className={'text-xs'}>{text}</p>
    </div>
  )
}

export const getLoadingStatusSection = () => {
  return statusSection({
    text: 'Analysing the profile to calculate the impersonation score.',
    icon: <Loader className={'w-5 h-5'} />,
    type: 'loading'
  })
}

export const getResultTypeOneStatusSection = ({ searchedImpersonator }) => {
  if (searchedImpersonator?.data?.takedown_id) {
    if (searchedImpersonator?.data?.takedown_status === 'completed') {
      return statusSection({
        text: 'This profile has already been taken down by Loti.',
        icon: <CheckCircle className={'w-5 h-5'} />,
        type: 'success'
      })
    } else {
      return statusSection({
        text: 'This profile has already been requested for takedown by Loti. We will keep checking progress of takedown every day.',
        icon: <CheckCircle className={'w-5 h-5'} />,
        type: 'success'
      })
    }
  } else {
    return statusSection({
      text: 'This profile appears to be an impersonation attempt and it has already been marked for takedown by Loti.',
      icon: <TriangleAlert className={'w-5 h-5'} />,
      type: 'danger'
    })
  }
}

export const getResultTypeZeroStatusSection = () => {
  return statusSection({
    text: 'This profile may or may not be trying to impersonate you. What would you like to do?',
    icon: <ShieldCheck className={'w-5 h-5'} />,
    type: 'success'
  })
}

export const getResultTypeNegativeOneStatusSection = () => {
  return statusSection({
    text: 'We are not sure if this profile is trying to impersonate you. What would you like to do?',
    icon: <ShieldCheck className={'w-5 h-5'} />,
    type: 'success'
  })
}

export const getResultTypeTwoStatusSection = ({ impersonatorScore }) => {
  return impersonatorScore
    ? statusSection({
        text: impersonatorScore?.impersonation
          ? 'This profile appears to be an impersonation attempt!'
          : 'This profile does not appear to be an impersonation attempt.',
        icon: impersonatorScore?.impersonation ? (
          <TriangleAlert className={'w-5 h-5'} />
        ) : (
          <ShieldCheck className={'w-5 h-5'} />
        ),
        type: impersonatorScore?.impersonation ? 'danger' : 'success'
      })
    : null
}
