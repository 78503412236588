import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { Loader2, SearchIcon, X } from 'lucide-react'
import NsfwImageSection from '@/components/NsfwImage/NsfwImage.jsx'
import { useDispatch } from 'react-redux'
import { useCallback, useEffect, useState, useRef } from 'react'
import { getSearchMedia } from '@/redux/MediaSearch/actions.js'
import ActionDock from '@/components/ActionDock/ActionDock.jsx'
import { useStore } from 'zustand'
import mediaSearchStore from '@/pages/MediaSearch/mediaSearchStore.js'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import { cn } from '@/lib/utils.js'
import { createTakedown } from '@/redux/Takedowns/actions.js'
import globalStore from '@/zustand/globalStore.js'
import { useNavigate, useParams } from 'react-router-dom'
import { useCheckResultType } from '@/pages/MediaSearch/hooks/useCheckResultsType.js'
import FileSearchIcon from '@/assets/icons/FileSearchIcon.jsx'
import BreadcrumbWrapper from '@/components/common/Wrappers/Breadcrumb/Breadcrumb.jsx'

const suggestions = [
  'Blood',
  'Drugs',
  'Smoking',
  'Nudity',
  'Violence',
  'Guns'
].sort((a, b) => a.localeCompare(b))

function MediaSearch() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { keyword } = useParams()
  const {
    mediaSearchData,
    setMediaSearchData,
    searchValue,
    setSearchValue,
    disableTakedownButton,
    setDisableTakedownButton,
    resetMediaSearchData,
    loading,
    infiniteScrollError
  } = useStore(mediaSearchStore)
  const { hideSensitiveContent } = useStore(globalStore)
  const [selectedItems, setSelectedItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const observer = useRef()
  const handleSearch = ({ lastKey, key, clearData }) => {
    if (searchValue || key) {
      if (clearData) setMediaSearchData({ data: null })
      setSelectedItems([])
      setSearchQuery(key ?? searchValue)
      navigate(`/search-media/${key ?? searchValue}`)
      dispatch(
        getSearchMedia({
          payload: { search: key ?? searchValue, ...(lastKey && { lastKey }) }
        })
      )
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (searchValue) handleSearch({ lastKey: null, clearData: true })
    }
  }

  const handleSuggestingClick = (key) => {
    if (searchValue === key) return
    setSearchValue(key)
    handleSearch({ lastKey: null, key, clearData: true })
  }

  const handleCheckboxChange = (itemId, isChecked) => {
    if (isChecked) {
      setSelectedItems((prev) => [...prev, itemId])
    } else {
      const excluded = selectedItems.filter((item) => item !== itemId)
      setSelectedItems(excluded)
    }
  }

  useCheckResultType({
    setDisableTakedownButton,
    selectedItems,
    data: mediaSearchData?.data
  })

  const handlePrimary = () => {
    const searchResultsIds = selectedItems.map(encodeURIComponent).join(',')
    let payload = {
      id: searchResultsIds,
      tab: 'mediaSearch',
      key: 'array'
    }

    if (selectedItems.length > 1) {
      payload = {
        ...payload,
        key: 'array'
      }
    }
    dispatch(createTakedown({ payload }))
    setSelectedItems([])
  }

  useEffect(() => {
    if (keyword) {
      setSearchValue(keyword)
      handleSearch({ lastKey: null, key: keyword, clearData: true })
    }
  }, [keyword])

  const handleDelete = () => {
    setSearchValue('')
    setSelectedItems([])
    resetMediaSearchData()
    setSearchQuery('')
    navigate('/search-media')
  }

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          !mediaSearchData?.isLastPage &&
          !infiniteScrollError
        ) {
          handleSearch({
            lastKey: mediaSearchData?.pageKeys[mediaSearchData?.currentPage + 1]
          })
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, mediaSearchData]
  )
  return (
    <div className='w-full max-w-screen-xl md:px-16 px-6 pb- relative'>
      <div className={'bg-green-50 -mx-[18rem] -my-8 border-b-2 border-muted'}>
        <div className='flex flex-col gap-4 mx-[17rem] pt-8 pb-6'>
          <div className='text-sm mb-4 flex items-center gap-2'>
            <FileSearchIcon className='w-4 h-4' />
            <BreadcrumbWrapper
              pages={[{ name: 'Watchtower', url: '/watchtower' }]}
              currentPage={{
                name: 'Media Search',
                url: '/media-search'
              }}
            />
          </div>

          <div className='flex flex-col gap-3'>
            <p className='text-2xl font-semibold text-foreground text-green-600'>
              Search Media
            </p>

            <div className='w-full relative'>
              <div className='relative shadow-sm'>
                <InputWrapper
                  className='w-full h-[3rem] flex items-center px-12 rounded-lg font-medium'
                  placeholder={'Keyword or description...'}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className='top-3 left-2 px-1 absolute'>
                <SearchIcon className={'w-6 h-6'} />
              </div>

              <div className='top-2 right-2 absolute'>
                <ButtonWrapper
                  text='Search'
                  onClick={handleSearch}
                  className='h-8 w-auto'
                />
              </div>
            </div>

            <div className='w-full flex gap-4 items-start'>
              <div className='text-muted-foreground text-sm font-regular mt-1'>
                <p>Suggestions:</p>
              </div>

              <div className={'flex items-center gap-x-4 flex-wrap'}>
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className={cn(
                      'rounded-lg py-1 text-sm flex items-center gap-1 font-medium cursor-pointer hover:text-green-600 border-[1px] border-transparent',
                      {
                        'bg-white text-background px-2 text-green-600 border-green-600':
                          searchValue === suggestion
                      }
                    )}
                  >
                    <div onClick={() => handleSuggestingClick(suggestion)}>
                      {suggestion}
                    </div>

                    {searchValue === suggestion && (
                      <div className={'cursor-pointer'} onClick={handleDelete}>
                        <X className={'w-4 h-4'} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='pt-16'>
        {loading ? (
          <SkeletonWrapper number={1} height={'3rem'} width={'100%'} />
        ) : searchQuery && mediaSearchData.data.length > 0 ? (
          <div className={'text-muted-foreground flexitems-center gap-1'}>
            <p>
              Results found for &quot;
              <span className='text-green-600 underline font-medium'>
                {searchQuery}
              </span>
              &quot;
            </p>
          </div>
        ) : (
          searchQuery && (
            <div className={'text-muted-foreground flex items-center gap-1'}>
              <p>
                No results found for &quot;
                <span className='text-green-600 underline font-medium'>
                  {searchQuery}
                </span>
                &quot;
              </p>
            </div>
          )
        )}
      </div>

      <div className='my-4 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 w-full items-center gap-4'>
        {mediaSearchData?.data?.map((item, index) => (
          <div
            key={index}
            className='w-full h-[20rem]'
            ref={
              index === mediaSearchData?.data?.length - 1
                ? lastElementRef
                : null
            }
          >
            <NsfwImageSection
              checkbox={true}
              title={item?.title}
              img={item?.frame_url}
              nsfw={false}
              imgFallback={item.face_url}
              intermediateFallbackImg={item.image_url}
              showMetaData={true}
              category={item?.category}
              checkboxData={{
                id: item?.search_results_id,
                checked: selectedItems?.includes(item?.search_results_id),
                onCheckedChange: (e) => {
                  handleCheckboxChange(item?.search_results_id, e)
                }
              }}
              className='w-full h-full'
              hideSensitiveContent={hideSensitiveContent}
              handleNavigation={() =>
                navigate(`/watchtower/${item.search_results_id}`)
              }
              imgPointer={true}
              link={item.page_url}
              takenDown={item?.takedown_status === 'completed'}
              takedown_id={item?.takedown_id}
            />
          </div>
        ))}
      </div>
      {loading && (
        <div className={'w-full flex items-center justify-center'}>
          <Loader2 className={'animate-spin w-10 h-10 text-green-600'} />
        </div>
      )}
      <div className='fixed bottom-0 z-50 w-[calc(100vw-45px)] md:w-[calc(100vw-125px)] lg:w-[calc(100vw-320px)] xl:max-w-[1160px]'>
        <div className={`flex justify-center transition-all duration-300 mb-2`}>
          <div
            className={`flex justify-center transition-all duration-300 mb-2 relative ${
              selectedItems?.length > 0
                ? 'opacity-100 max-h-20'
                : 'opacity-0 max-h-0'
            }`}
          >
            <div className={'absolute w-fit bottom-0'}>
              <ActionDock
                dataLength={selectedItems?.length}
                primaryAction={{
                  text: 'Mark for takedown',
                  alertTitle: 'Mark for takedown',
                  alertDescription:
                    "Marking this for takedown will kick-off Loti's takedown service. Please be certain that this content is unwanted before proceeding.",
                  alertOk: 'Continue',
                  alertCancel: 'Cancel',
                  handlePrimary,
                  disabled: disableTakedownButton
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaSearch
