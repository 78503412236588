import { useEffect } from 'react'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore.js'
import { useNavigate } from 'react-router-dom'
import {
  MarkForTakedownButton,
  TakedownInfoButton,
  WhiteListButton
} from '@/components/SideNav/AddImpersonator/UserInfoCard/Buttons/FooterButtons.jsx'
import {
  getLoadingStatusSection,
  getResultTypeNegativeOneStatusSection,
  getResultTypeOneStatusSection,
  getResultTypeTwoStatusSection,
  getResultTypeZeroStatusSection
} from '@/components/SideNav/AddImpersonator/UserInfoCard/StatusSections/StatusSections.jsx'

function UserInfoCard({
  searchedImpersonator,
  checkScoreLoading,
  resultType,
  handleAddImpersonator,
  addImpersonatorLoading,
  setOpen,
  handleNotAnImpersonator
}) {
  const {
    setImpersonatorScore,
    setSearchedImpersonator,
    setImpersonatorAdded,
    // setSelectedImpersonatorPlatform,
    // setImpersonatorSearchValue,
    whitelistLoading,
    impersonatorScore
  } = useStore(takedownStore)
  const navigate = useNavigate()
  useEffect(() => {
    return () => {
      setImpersonatorScore(null)
      setSearchedImpersonator(null)
      setImpersonatorAdded(false)
      // setSelectedImpersonatorPlatform(null)
      // setImpersonatorSearchValue('')
    }
  }, [])

  const getStatusSection = () => {
    if (checkScoreLoading && resultType === 2) {
      return getLoadingStatusSection()
    }

    switch (resultType) {
      case 1:
        return getResultTypeOneStatusSection({ searchedImpersonator })
      case 0:
        return getResultTypeZeroStatusSection()
      case -1:
        return getResultTypeNegativeOneStatusSection()
      case 2:
        return getResultTypeTwoStatusSection({ impersonatorScore })
      default:
        return null
    }
  }

  const handleNavigate = () => {
    setOpen(false)
    navigate(
      `/takedown/${searchedImpersonator?.data?.takedown_id}?selectedTab=accounts`
    )
  }

  const getFooterButtons = () => {
    if (
      (resultType === 1 && !searchedImpersonator?.data?.takedown_id) ||
      (resultType === 2 && !checkScoreLoading) ||
      resultType === -1 ||
      resultType === 0
    ) {
      return (
        <div className={'flex items-center gap-2'}>
          <WhiteListButton
            addImpersonatorLoading={addImpersonatorLoading}
            handleNotAnImpersonator={handleNotAnImpersonator}
            searchedImpersonator={searchedImpersonator}
            handleAddImpersonator={handleAddImpersonator}
            whitelistLoading={whitelistLoading}
          />
          <MarkForTakedownButton
            searchedImpersonator={searchedImpersonator}
            handleAddImpersonator={handleAddImpersonator}
            addImpersonatorLoading={addImpersonatorLoading}
            resultType={resultType}
            impersonatorScore={impersonatorScore}
          />
        </div>
      )
    } else if (resultType === 1 && searchedImpersonator?.data?.takedown_id) {
      return <TakedownInfoButton handleNavigate={handleNavigate} />
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={' rounded-lg p-4 shadow-sm border flex flex-col gap-3'}>
        <div className={'flex items-center gap-4'}>
          <div className={'w-16 h-16 rounded-full overflow-hidden'}>
            <img
              src={
                resultType !== 2
                  ? searchedImpersonator?.data?.profile_image
                  : searchedImpersonator?.data?.profile_pic_base64
              }
              alt={'userImg'}
              className={'object-cover w-full h-full'}
            />
          </div>
          <div className={'flex flex-col gap-2'}>
            <div>
              <p className={'text font-medium'}>
                @{searchedImpersonator?.data?.username}
              </p>
            </div>
            <div className={'flex items-center gap-5'}>
              {resultType === 2 && (
                <div className={' text-muted-foreground'}>
                  <p className={'text-xs'}>Posts</p>
                  <p className={'font-semibold'}>
                    {searchedImpersonator?.data?.media_count || 0}
                  </p>
                </div>
              )}
              <div className={'text-muted-foreground'}>
                <p className={'text-xs'}>Followers</p>
                <p className={'font-semibold'}>
                  {resultType !== 2
                    ? searchedImpersonator?.data?.followers?.toLocaleString(
                        'en-US'
                      ) || 0
                    : searchedImpersonator?.data?.follower_count?.toLocaleString(
                        'en-US'
                      ) || 0}
                </p>
              </div>
              <div className={'text-muted-foreground'}>
                <p className={'text-xs'}>Following</p>
                <p className={'font-semibold'}>
                  {resultType !== 2
                    ? searchedImpersonator?.data?.following?.toLocaleString(
                        'en-US'
                      ) || 0
                    : searchedImpersonator?.data?.following_count?.toLocaleString(
                        'en-US'
                      ) || 0}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={'flex flex-col gap-3'}>
          {getStatusSection()}
          {getFooterButtons()}
        </div>
      </div>
    </div>
  )
}

export default UserInfoCard
