import globalStore from '@/zustand/globalStore'
import * as actionTypes from './actionTypes'
import takedownStore from '@/pages/Takedowns/takedownStore'
import watchtowerStore from '@/pages/Watchtower/watchtowerStore'

const initialState = {
  error: false,
  loading: false,
  takedowns: null,
  errorMessage: '',
  takedownSummary: null,
  takedownSummaryCount: null,
  takedownSummaryLoading: false
}

const takedownsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TAKEDOWNS:
      takedownStore.getState().setLoading(true)
      takedownStore.getState().setError(false)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    case actionTypes.FETCH_TAKEDOWNS_SUCCESS: {
      const { results, lastKey, category } = action.payload
      const takedownStoreState = takedownStore.getState()
      takedownStoreState.setLoading(false)
      takedownStore.getState().setError(false)
      takedownStoreState.setTakedownData(category, {
        data: results,
        lastKey,
        isLastPage: lastKey ? false : true
      })
      const currentPage = takedownStoreState.takedownData[category].currentPage
      takedownStoreState.setPageKey(category, currentPage + 1, lastKey)

      return {
        ...state,
        loading: false,
        takedowns: action.payload.data
      }
    }
    case actionTypes.FETCH_TAKEDOWNS_FAILURE:
      takedownStore.getState().setLoading(false)
      takedownStore.getState().setError(true)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    case actionTypes.FETCH_SINGLE_TAKEDOWN:
      takedownStore.getState().setTakedownError(false)
      globalStore.getState().setLoading(true)
      takedownStore.getState().setTakedownLoading(true)
      takedownStore.getState().setError(false)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    case actionTypes.FETCH_SINGLE_TAKEDOWN_SUCCESS: {
      globalStore.getState().setLoading(false)
      takedownStore.getState().setTakedownLoading(false)
      takedownStore.getState().setTakedownError(false)
      takedownStore.getState().setSingleTakedown(action.payload)
      takedownStore.getState().setError(false)
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.FETCH_SINGLE_TAKEDOWN_FAILURE:
      takedownStore.getState().setTakedownLoading(false)
      takedownStore.getState().setTakedownError(true)
      globalStore.getState().setLoading(false)
      takedownStore.getState().setError(true)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    case actionTypes.FETCH_MAILS:
      globalStore.getState().setLoading(true)
      takedownStore.getState().setMailsLoading(true)
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    case actionTypes.FETCH_MAILS_SUCCESS: {
      globalStore.getState().setLoading(false)
      takedownStore.getState().setMailsLoading(false)
      takedownStore.getState().setMails(action.payload)
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.FETCH_MAILS_FAILURE:
      takedownStore.getState().setMailsLoading(false)
      globalStore.getState().setLoading(false)
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    case actionTypes.CREATE_TAKEDOWN:
      globalStore.getState().setLoading(true)
      watchtowerStore.getState().setMarkForTakedownStatus({ success: null })
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: ''
      }
    case actionTypes.CREATE_TAKEDOWN_SUCCESS: {
      globalStore.getState().setLoading(false)
      watchtowerStore.getState().setMarkForTakedownStatus({ success: true })
      return {
        ...state,
        loading: false
      }
    }
    case actionTypes.CREATE_TAKEDOWN_FAILURE:
      globalStore.getState().setLoading(false)
      watchtowerStore.getState().setMarkForTakedownStatus({ success: false })
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload
      }
    case actionTypes.GET_TAKEDOWN_SUMMARY: {
      takedownStore.getState().setTakedownSummaryLoading(true)
      return {
        ...state,
        takedownSummaryLoading: true
      }
    }
    case actionTypes.GET_TAKEDOWN_SUMMARY_SUCCESS: {
      const takedownStoreState = takedownStore.getState()
      takedownStoreState.setTakedownSummaryLoading(false)
      takedownStoreState.setTakedownSummary(action.payload)
      return {
        ...state,
        takedownSummaryLoading: false,
        takedownSummary: action.payload.data
      }
    }
    case actionTypes.GET_TAKEDOWN_SUMMARY_FAILURE:
      takedownStore.getState().setTakedownSummaryLoading(false)
      return {
        ...state,
        takedownSummaryLoading: false
      }

    case actionTypes.SEARCH_IMPERSONATOR: {
      takedownStore.getState().setImpersonatorSearchLoading(true)
      return {
        ...state,
        impersonatorSearchLoading: true
      }
    }
    case actionTypes.SEARCH_IMPERSONATOR_SUCCESS: {
      takedownStore.getState().setImpersonatorSearchLoading(false)
      takedownStore.getState().setSearchedImpersonator(action.payload)
      return {
        ...state,
        impersonatorSearchLoading: false
      }
    }
    case actionTypes.SEARCH_IMPERSONATOR_FAILURE:
      takedownStore.getState().setImpersonatorSearchLoading(false)
      return {
        ...state,
        impersonatorSearchLoading: false
      }

    case actionTypes.CHECK_IMPERSONATOR_SCORE: {
      takedownStore.getState().setCheckScoreLoading(true)
      takedownStore.getState().setCheckScoreError(false)
      return {
        ...state,
        checkScoreLoading: true
      }
    }
    case actionTypes.CHECK_IMPERSONATOR_SCORE_SUCCESS: {
      takedownStore.getState().setCheckScoreLoading(false)
      takedownStore.getState().setImpersonatorScore(action.payload)
      takedownStore.getState().setCheckScoreError(false)
      return {
        ...state,
        checkScoreLoading: false
      }
    }
    case actionTypes.CHECK_IMPERSONATOR_SCORE_FAILURE:
      takedownStore.getState().setCheckScoreLoading(false)
      takedownStore.getState().setCheckScoreError(true)
      return {
        ...state,
        checkScoreLoading: false
      }

    case actionTypes.ADD_IMPERSONATOR: {
      takedownStore.getState().setAddImpersonatorLoading(true)
      takedownStore.getState().setImpersonatorAdded(false)
      return {
        ...state,
        checkScoreLoading: true
      }
    }
    case actionTypes.ADD_IMPERSONATOR_SUCCESS: {
      takedownStore.getState().setAddImpersonatorLoading(false)
      takedownStore.getState().setImpersonatorAdded(true)
      return {
        ...state,
        checkScoreLoading: false
      }
    }
    case actionTypes.ADD_IMPERSONATOR_FAILURE:
      takedownStore.getState().setAddImpersonatorLoading(false)
      takedownStore.getState().setImpersonatorAdded(false)
      return {
        ...state,
        checkScoreLoading: false
      }

    default:
      return state
  }
}

export default takedownsReducer
