import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../ui/select'

export default function SelectWrapper({
  selectItem,
  setValue,
  value,
  disabled,
  placeholder
}) {
  return (
    <Select
      onValueChange={(value) => {
        setValue(value)
      }}
      value={value || ''}
    >
      <SelectTrigger disabled={disabled} className={'focus:ring-0'}>
        <SelectValue className='font-medium' placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {selectItem.map((item, index) => (
            <SelectItem key={index} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
