import { AlertDialogWrapper } from '@/components/common/Wrappers/AlertDialog/AlertDialog.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { Info, ShieldIcon, UserMinusIcon } from 'lucide-react'

export const MarkForTakedownButton = ({
  resultType,
  searchedImpersonator,
  addImpersonatorLoading,
  handleAddImpersonator,
  impersonatorScore
}) => {
  const getAlertDescription = () => {
    if (resultType === 2 && !impersonatorScore) {
      return (
        <p>
          Are you sure that this profile is an impersonation attempt?
          <span className={'font-bold'}>
            {' '}
            Note that the takedown request is irreversible and will be sent to
            the platform.
          </span>
        </p>
      )
    } else if (resultType === 2 && !impersonatorScore?.impersonation) {
      return (
        <p>
          This profile{' '}
          <span className={'font-bold'}>
            does not appear to be an impersonation.{' '}
          </span>
          If you still wish to proceed,
          <span className={'font-bold'}>
            {' '}
            note that the takedown request is irreversible and will be sent to
            the platform.
          </span>
        </p>
      )
    } else
      return 'Are you sure you want to mark this account for takedown? Please be certain because this action is irreversible.'
  }

  return (
    <AlertDialogWrapper
      trigger={
        <ButtonWrapper
          className={
            'bg-primary text-white h-10 px-4 rounded-lg focus:outline-none w-full'
          }
          text={
            resultType === 1 && !searchedImpersonator?.data?.takedown_id
              ? 'Takedown now'
              : 'Mark for Takedown'
          }
          icon={<ShieldIcon className={'w-4'} strokeWidth={1.8} />}
          loading={addImpersonatorLoading}
          disabled={
            searchedImpersonator?.data?.takedown_id ||
            // (resultType === 0 && checkScoreLoading) ||
            addImpersonatorLoading
          }
        />
      }
      className={'w-[30rem]'}
      alertTitle={'Mark for takedown.'}
      alertDescription={getAlertDescription()}
      handleOk={handleAddImpersonator}
      alertOk={'Confirm'}
      alertCancel={'Cancel'}
    />
  )
}

export const TakedownInfoButton = ({ handleNavigate }) => {
  return (
    <ButtonWrapper
      className={
        'bg-primary text-white h-10 px-4 rounded-lg focus:outline-none w-full'
      }
      text={'Takedown Info'}
      icon={<Info className={'w-4'} strokeWidth={1.8} />}
      onClick={handleNavigate}
    />
  )
}
export const WhiteListButton = ({
  whitelistLoading,
  handleNotAnImpersonator,
  searchedImpersonator,
  addImpersonatorLoading
}) => {
  return (
    <ButtonWrapper
      className={
        'bg-primary text-white h-10 px-4 rounded-lg focus:outline-none w-full'
      }
      text={'Whitelist'}
      icon={<UserMinusIcon className={'w-4'} strokeWidth={1.8} />}
      onClick={handleNotAnImpersonator}
      loading={whitelistLoading}
      disabled={
        searchedImpersonator?.data?.takedown_id ||
        // (resultType === 0 && checkScoreLoading) ||
        addImpersonatorLoading
      }
    />
  )
}
