import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
const initialTabState = {
  lastKey: null,
  pageKeys: {},
  currentPage: 1,
  isLastPage: false,
  data: null,
  takedownSummary: null,
  takedownSummaryLoading: false,
  takedownSummaryCount: null,
  appliedFilters: {}
}
const takedownDataInitialState = {
  media: { ...initialTabState },
  accounts: { ...initialTabState }
}
const takedownStore = create(
  devtools((set) => ({
    takedownSummary: initialTabState.takedownSummary,
    setTakedownSummary: (value) => set({ takedownSummary: value }),
    takedownSummaryLoading: initialTabState.takedownSummaryLoading,
    setTakedownSummaryLoading: (value) =>
      set({ takedownSummaryLoading: value }),
    takedownSummaryCount: null,
    setTakedownSummaryCount: (value) => set({ takedownSummaryCount: value }),

    takedownData: takedownDataInitialState,
    setTakedownData: (tab, data) =>
      set((state) => ({
        takedownData: {
          ...state.takedownData,
          [tab]: {
            ...state.takedownData[tab],
            ...data
          }
        }
      })),

    loading: false,
    setLoading: (value) => set({ loading: value }),

    error: false,
    setError: (value) => set({ error: value }),

    lastKey: null,
    setLastKey: (value) => set({ lastKey: value }),

    pageKeys: [],

    isLastPage: false,
    setIsLastPage: (value) => set({ isLastPage: value }),

    currentPage: 1,
    setCurrentPage: (value) => set({ currentPage: value }),
    resetPageKeys: () => set({ pageKeys: [] }),

    searchValue: '',
    setSearchValue: (value) => set(() => ({ searchValue: value })),

    sourceValue: [],
    setSourceValue: (value) => set(() => ({ sourceValue: value })),

    singleTakedown: null,
    setSingleTakedown: (value) => set({ singleTakedown: value }),

    mails: null,
    setMails: (value) => set({ mails: value }),

    mailsLoading: false,
    setMailsLoading: (value) => set({ mailsLoading: value }),

    takedownLoading: false,
    setTakedownLoading: (value) => set({ takedownLoading: value }),

    takedownError: false,
    setTakedownError: (value) => set({ takedownError: value }),

    sources: [],
    setSources: (value) => set(() => ({ sources: value })),

    platformValue: {
      media: [],
      accounts: []
    },

    setPlatformValue: (key, value) => {
      set((state) => ({
        platformValue: {
          ...state.platformValue,
          [key]: value
        }
      }))
    },

    statusValue: {
      media: [],
      accounts: []
    },
    setStatusValue: (key, value) =>
      set((state) => ({
        statusValue: {
          ...state.statusValue,
          [key]: value
        }
      })),

    dateValue: { media: null, accounts: null },
    setDateValue: (key, value) =>
      set((state) => ({
        dateValue: {
          ...state.dateValue,
          [key]: value
        }
      })),

    selectedColumns: ['title', 'source', 'status', 'category', 'last_modified'],

    setSelectedColumns: (value) => set(() => ({ selectedColumns: value })),

    selectedTab: 'media',
    setSelectedTab: (value) => set({ selectedTab: value }),

    setPageKey: (tab, page, key) =>
      set((state) => ({
        takedownData: {
          ...state.takedownData,
          [tab]: {
            ...state.takedownData[tab],
            pageKeys: { ...state.takedownData[tab].pageKeys, [page]: key }
          }
        }
      })),

    createTakedownForm: {
      url: '',
      category: ''
    },
    setCreateTakedownForm: (key, value) => {
      set((state) => ({
        createTakedownForm: {
          ...state.createTakedownForm,
          [key]: value
        }
      }))
    },

    impersonatorSearchValue: '',
    setImpersonatorSearchValue: (value) =>
      set({ impersonatorSearchValue: value }),

    searchedImpersonator: null,
    setSearchedImpersonator: (value) => set({ searchedImpersonator: value }),

    impersonatorSearchLoading: false,
    setImpersonatorSearchLoading: (value) =>
      set({ impersonatorSearchLoading: value }),

    selectedImpersonatorPlatform: null,
    setSelectedImpersonatorPlatform: (value) =>
      set({ selectedImpersonatorPlatform: value }),

    checkScoreLoading: false,
    setCheckScoreLoading: (value) => set({ checkScoreLoading: value }),

    checkScoreError: false,
    setCheckScoreError: (value) => set({ checkScoreError: value }),

    impersonatorScore: null,
    setImpersonatorScore: (value) => set({ impersonatorScore: value }),

    addImpersonatorLoading: false,
    setAddImpersonatorLoading: (value) =>
      set({ addImpersonatorLoading: value }),

    impersonatorAdded: false,
    setImpersonatorAdded: (value) => set({ impersonatorAdded: value }),

    whitelistLoading: false,
    setWhitelistLoading: (value) => set({ whitelistLoading: value }),

    whiteListed: false,
    setWhiteListed: (value) => set({ whiteListed: value }),

    clearFilters: () => {
      set(() => ({ searchValue: '' }))
      set(() => ({ statusValue: [] }))
      set(() => ({ sourceValue: [] }))
      set(() => ({ dateValue: null }))
    },
    clear: () =>
      set({
        sources: [],
        searchValue: '',
        statusValue: [],
        sourceValue: [],
        dateValue: null,
        selectedColumns: [
          'title',
          'status',
          'source',
          'category',
          'last_modified'
        ]
      })
  }))
)

export default takedownStore
