import SelectWrapper from '@/components/common/Wrappers/Select/SelectWrapper.jsx'
import InputWrapper from '@/components/common/Wrappers/Input/InputWrapper.jsx'
import ButtonWrapper from '@/components/common/Wrappers/Button/ButtonWrapper.jsx'
import { SearchIcon } from 'lucide-react'

function SearchUser({
  platforms,
  setSelectedImpersonatorPlatform,
  selectedImpersonatorPlatform,
  impersonatorSearchValue,
  setImpersonatorSearchValue,
  handleSearch,
  impersonatorSearchLoading
}) {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }
  return (
    <div className={'w-full flex flex-col gap-3'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'font-medium text-sm'}>
          <p>Platform</p>
        </div>
        <div className={'w-full'}>
          <SelectWrapper
            selectItem={platforms}
            setValue={(value) => {
              setSelectedImpersonatorPlatform(value)
            }}
            placeholder={'Select platform'}
            value={selectedImpersonatorPlatform}
          />
        </div>
      </div>
      <div className={'w-full flex flex-col gap-1'}>
        <div>
          <div className={'font-medium text-sm'}>
            <p>Username</p>
          </div>
        </div>
        <div className='w-full'>
          <InputWrapper
            type={'text'}
            placeholder={'Username'}
            className={
              'w-full h-10 px-4 bg-background border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary'
            }
            value={impersonatorSearchValue}
            onChange={(e) => setImpersonatorSearchValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className={'flex items-center justify-end gap-6'}>
        <div>
          <ButtonWrapper
            className={
              'bg-primary text-white h-10 px-4 rounded-lg focus:outline-none'
            }
            icon={<SearchIcon className={'w-4'} />}
            text={'Search'}
            onClick={handleSearch}
            disabled={impersonatorSearchLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchUser
