import { UserPlus } from 'lucide-react'
import DialogWrapper from '@/components/common/Wrappers/Dialog/DialogWrapper.jsx'
import SocialIcon from '@/components/common/ui/social-icon.jsx'
import { useStore } from 'zustand'
import takedownStore from '@/pages/Takedowns/takedownStore.js'
import { useDispatch } from 'react-redux'
import {
  addImpersonator,
  checkImpersonatorScore,
  searchImpersonator
} from '@/redux/Takedowns/actions.js'
import { toast } from 'sonner'
import SkeletonWrapper from '@/components/common/Wrappers/Skeleton/SkeletonWrapper.jsx'
import globalStore from '@/zustand/globalStore.js'
import SearchUser from '@/components/SideNav/AddImpersonator/SearchUser/SearchUser.jsx'
import UserInfoCard from '@/components/SideNav/AddImpersonator/UserInfoCard/UserInfoCard.jsx'
import { useEffect, useState } from 'react'
import { setIsMe } from '@/redux/Watchtower/actions.js'
const platforms = [
  {
    label: (
      <div className={'flex gap-2 items-center'}>
        <p>
          <SocialIcon platform={'instagram'} />
        </p>
        <p>Instagram</p>
      </div>
    ),
    value: 'instagram'
  },
  {
    label: (
      <div className={'flex gap-2 items-center'}>
        <p>
          <SocialIcon platform={'facebook'} />
        </p>
        <p>Facebook</p>
      </div>
    ),
    value: 'facebook'
  },
  {
    label: (
      <div className={'flex gap-2 items-center'}>
        <p>
          <SocialIcon platform={'tiktok'} />
        </p>
        <p>Tiktok</p>
      </div>
    ),
    value: 'tiktok'
  },
  {
    label: (
      <div className={'flex gap-2 items-center'}>
        <p>
          <SocialIcon platform={'pinterest'} />
        </p>
        <p>Pinterest</p>
      </div>
    ),
    value: 'pinterest'
  }
]
function AddImpersonator() {
  const {
    impersonatorSearchValue,
    setImpersonatorSearchValue,
    selectedImpersonatorPlatform,
    setSelectedImpersonatorPlatform,
    searchedImpersonator,
    impersonatorSearchLoading,
    impersonatorScore,
    checkScoreLoading,
    setSearchedImpersonator,
    setImpersonatorScore,
    addImpersonatorLoading,
    impersonatorAdded,
    whiteListed
  } = useStore(takedownStore)
  const { userMetaData } = useStore(globalStore)
  const [resultType, setResultType] = useState(null)
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  //* match = -1 => WhiteListed (from DB)
  //* match = 0 => Review area result (from DB)
  //* match = 1 => Impersonator (from DB)
  //* match = 2 => Scraped profile

  useEffect(() => {
    setResultType(
      searchedImpersonator?.data && 'match' in searchedImpersonator.data
        ? searchedImpersonator?.data?.match
        : 2
    )
  }, [searchedImpersonator])

  const handleCheckScore = () => {
    dispatch(
      checkImpersonatorScore({
        payload: {
          platform: selectedImpersonatorPlatform,
          username: impersonatorSearchValue,
          user_id: userMetaData.user_id
        }
      })
    )
  }

  const handleSearch = () => {
    if (impersonatorSearchValue !== '' && selectedImpersonatorPlatform) {
      setSearchedImpersonator(null)
      dispatch(
        searchImpersonator({
          payload: {
            platform: selectedImpersonatorPlatform,
            search: impersonatorSearchValue
          }
        })
      )
      setImpersonatorScore(null)
      handleCheckScore()
    } else {
      toast.info('Please enter a username and select a platform to search.')
    }
  }

  const getFormattedPayload = () => {
    return {
      platform: selectedImpersonatorPlatform,
      profile_pic_url:
        resultType !== 2
          ? searchedImpersonator?.data?.profile_image
          : searchedImpersonator?.data?.profile_pic_url,
      username: searchedImpersonator?.data?.username,
      followers:
        resultType !== 2
          ? searchedImpersonator?.data?.followers
          : searchedImpersonator?.data?.follower_count,
      following:
        resultType !== 2
          ? searchedImpersonator?.data?.following
          : searchedImpersonator?.data?.following_count
    }
  }

  const handleAddImpersonator = () => {
    dispatch(addImpersonator({ payload: getFormattedPayload() }))
  }

  const handleNotAnImpersonator = () => {
    dispatch(
      setIsMe({
        payload: {
          ids: `${searchedImpersonator?.data?.username}#${selectedImpersonatorPlatform}`,
          isMe: false,
          type: 'impersonations',
          tab: 'reviewed',
          whiteList: true
        }
      })
    )
  }

  useEffect(() => {
    if (impersonatorAdded || whiteListed) {
      setOpen(false)
    }
  }, [impersonatorAdded, whiteListed])
  return (
    <div className={'w-full'}>
      <div className={'w-full h-full'}>
        <DialogWrapper
          trigger={
            <div
              className={
                'flex items-center gap-2 text-sm font-normal cursor-pointer'
              }
            >
              <UserPlus className={'w-4'} />
              <p>Add Impersonator</p>
            </div>
          }
          open={open}
          setOpen={setOpen}
          title={'Add Impersonator'}
          description={
            'Add a new impersonator by entering their social media username below.'
          }
        >
          <div className={'w-full h-full flex flex-col gap-4'}>
            <SearchUser
              handleSearch={handleSearch}
              impersonatorSearchLoading={impersonatorSearchLoading}
              impersonatorSearchValue={impersonatorSearchValue}
              setImpersonatorSearchValue={setImpersonatorSearchValue}
              selectedImpersonatorPlatform={selectedImpersonatorPlatform}
              platforms={platforms}
              setSelectedImpersonatorPlatform={setSelectedImpersonatorPlatform}
            />

            {impersonatorSearchLoading ? (
              <div className={'w-full'}>
                <SkeletonWrapper width={'100%'} height={'12rem'} number={1} />
              </div>
            ) : (
              searchedImpersonator && (
                <UserInfoCard
                  checkScoreLoading={checkScoreLoading}
                  impersonatorScore={impersonatorScore}
                  searchedImpersonator={searchedImpersonator}
                  resultType={resultType}
                  handleAddImpersonator={handleAddImpersonator}
                  addImpersonatorLoading={addImpersonatorLoading}
                  handleNotAnImpersonator={handleNotAnImpersonator}
                  open={open}
                  setOpen={setOpen}
                />
              )
            )}
          </div>
        </DialogWrapper>
      </div>
    </div>
  )
}

export default AddImpersonator
